function initMap() {
	var MAP_TYPE_ID = 'BVAM_COLORED';
	var position = new google.maps.LatLng(35.687189, 139.743133);

	var zoom = 15;
	var center = new google.maps.LatLng(35.6854674,139.7498159);

	var zoomSp = 14;
	var centerSp = new google.maps.LatLng(35.6871406,139.7494296);

	var mapStyle = [
	    {
	        "featureType": "all",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "color": "#293037"
	            },
	            {
	                "visibility": "on"
	            }
	        ]
	    },
	    {
	        "featureType": "administrative",
	        "elementType": "all",
	        "stylers": [
	            {
	                "visibility": "simplified"
	            }
	        ]
	    },
	    {
	        "featureType": "all",
	        "elementType": "labels",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": 30
	            },
	            {
	                "saturation": 30
	            },
	            {
	                "color": "#13325a"
	            },
	            {
	                "visibility": "on"
	            }
	        ]
	    },
	    {
	        "featureType": "poi",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "saturation": "0"
	            },
	            {
	                "lightness": "0"
	            },
	            {
	                "gamma": "0.30"
	            },
	            {
	                "weight": "0.01"
	            }
	        ]
	    },
	    {
	        "featureType": "poi",
	        "elementType": "geometry.fill",
	        "stylers": [
	            {
	                "color": "#1f446d"
	            },
	            {
	                "visibility": "on"
	            }
	        ]
	    },
	    {
	        "featureType": "poi",
	        "elementType": "geometry.stroke",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "poi.park",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": "100"
	            },
	            {
	                "saturation": "-20"
	            },
	            {
	                "visibility": "simplified"
	            },
	            {
	                "color": "#344150"
	            },
	            {
	                "gamma": "0.92"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": 10
	            },
	            {
	                "saturation": -30
	            },
	            {
	                "color": "#0a273c"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry.stroke",
	        "stylers": [
	            {
	                "saturation": "-100"
	            },
	            {
	                "lightness": "-100"
	            },
	            {
	                "gamma": "0.00"
	            },
	            {
	                "color": "#0076ff"
	            },
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "road.highway",
	        "elementType": "geometry.fill",
	        "stylers": [
	            {
	                "color": "#232c37"
	            },
	            {
	                "visibility": "on"
	            }
	        ]
	    },
	    {
	        "featureType": "road.highway",
	        "elementType": "geometry.stroke",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "transit",
	        "elementType": "all",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "transit",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "visibility": "simplified"
	            },
	            {
	                "color": "#20436f"
	            }
	        ]
	    },
	    {
	        "featureType": "transit.station.airport",
	        "elementType": "all",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "water",
	        "elementType": "all",
	        "stylers": [
	            {
	                "lightness": -20
	            },
	            {
	                "color": "#0b1929"
	            }
	        ]
	    }
	];

	function isLayoutSp() {
		return (window.innerWidth < 812.01);
	};

	var mapConfig = {
		zoom : isLayoutSp() ? zoomSp : zoom,
		center : isLayoutSp() ? centerSp : center,
		backgroundColor: '#021126',
		disableDefaultUI : true,
		disableDoubleClickZoom : true,
		draggable : false,
		clickableIcons : false,
		fullscreenControl : false,
		gestureHandling : 'none',
		keyboardShortcuts : false,
		mapTypeControl : false,
		panControl : false,
		rotateControl : false,
		scaleControl : false,
		scrollwheel : false,
		streetViewControl : false,
		styles : mapStyle,
		zoomControl : false
	};

	// Create a map object and specify the DOM element for display.
	var map = new google.maps.Map(document.getElementById('topContactMapBody'), mapConfig);
/*
	var marker = new google.maps.Marker({
		position : position,
		map : map
	});
*/

	var resetLayout = function(){
		if (isLayoutSp()) {
			map.setCenter(centerSp);

			if (map.getZoom() != zoomSp) {
				map.setZoom(zoomSp);
			}
		} else {
			map.setCenter(center);

			if (map.getZoom() != zoom) {
				map.setZoom(zoom);
			}
		}
	};

	var _timer = 0;

	$(window).on('resize load', function(){
		if (_timer) {
			clearTimeout(_timer);
		}

		_timer = setTimeout(resetLayout, 200);
	});
};



(function(win,$){
/**
 * ・スクロール制御
 * ・ローディング完了後アニメーション
 * ・グローバルナビゲーション
 * ・ローディング
 * ・地図上要素位置
 */

	var $body = $('body');

	/**
	 * スクロール制御
	 * @namespace
	 * @require jquery.scrollify.js
	 */
	var scroll = {
		init: function(){
			this.$navWrap   = $('.jsTopNav');
			this.$scrollBtn = $('.jsScrollBtn');
			this.$nav       = this.$navWrap.find('li:not(.topNavAdditional)');
			this.$globalNav = $('.modGlobalNavUpper > li');

			//スクロール画面数
			this.max = this.getAllSection().length;

			//表示中コンテンツindex
			this.current = 0;

			this.initCurrent();
		},

		initCurrent: function(){
			// 現在のハッシュから選択されているセクションを取得
			var sectionName = '';
			if (location.hash) {
				sectionName = location.hash.replace(/^#/, '');

//				if (window.sessionStorage && sessionStorage.getItem('isAnimateSkip')) {
					var $section = this.getSection(sectionName);
					if (sectionName && $section.length) {
						var top = $section.offset().top;
						$('html,body').stop().scrollTop(top);
					}
//				}
			}

			this.current = this.getSectionIndex(sectionName);
		},

		main: function(){
			var _self = this;

			this.init();

			$('[data-section-name].isActive').removeClass('isActive');

			if (window.sessionStorage) {
				var json = sessionStorage.getItem('ShownSections');
				var data = JSON.parse(json || '{}');
				if (!data || typeof data != 'object') {
					data = {};
				}

				for (var name in data) {
					if (data.hasOwnProperty(name)) {
						var $section = $('[data-section-name="' + name + '"]');
						$section
							.addClass('isAnimateSkip')
							.find('.topContentBtn')
							.addClass('animateEnd')
						;
					}
				}
			}

			$.scrollify({
				section: '.topContent',
				easing: 'easeOutExpo',
				scrollSpeed: 1200,
				scrollbars: false,
				before: function(index, section){
					for(var i=0; i<section.length; i++){
						var $target = $(section[i]);
						$target.removeClass('isCurrentBefore');
						$target.removeClass('isCurrent');
						if($target.hasClass('isActive')){
							$target.addClass('isRemove');
							if(_self.isMinus(index)){
								$target.addClass('isMinus');
							}
						}
					}

					var $old = $(section[_self.current]);
					var $selected = $(section[index]);

					$old.addClass('isCurrentBefore');
					$selected
						.addClass('isCurrent')
						.addClass('isScrolling')		//スクロール中のみ
						.addClass('isActive')
					;

					_self.current = index;
					_self.switchCurrent(index);
					_self.switchScroll(index);
					_self.setNavPos(index);

					if (window.sessionStorage) {
						var json = sessionStorage.getItem('ShownSections');
						var data = JSON.parse(json || '{}');
						if (!data || typeof data != 'object') {
							data = {};
						}

						data[$selected.attr('data-section-name')] = true;
						sessionStorage.setItem('ShownSections', JSON.stringify(data));
					}
				},
				after: function(index, section){
					var $selected = $(section[index]);
					$selected.removeClass('isScrolling');

					for(var i=0; i<section.length; i++){
						var $target = $(section[i]);
						if($target.hasClass('isRemove')){
							$target.removeClass('isRemove').removeClass('isMinus');
						}

						if($target.hasClass('isCurrentBefore')){
							$target.removeClass('isCurrentBefore');
						}

						if(i < index){
							$target.addClass('isPrev');
							$target.off('transitionend.scrollifyAfter');
						}else{
							$target.on('transitionend.scrollifyAfter', function(){
								$(this).removeClass('isPrev');
							});
						}
					}
				},
				afterResize: function(){
					$.scrollify.update();

					//リサイズ時ナビゲーション連動調整
					_self.setNavPos(_self.current);

					//地図上要素位置制御
					map.setPosition();
				},
				afterRender: function(){
					$('.topContentMain').addClass('isActive');
				}
			});

			_self.pagination();
			_self.setNavPos(_self.current);
			_self.switchCurrent(_self.current);

			_self.$scrollBtn.on('click', function(e){
				if ($(e.currentTarget).closest('.topScrollDown').length) {
					$.scrollify.next();
				} else {
					$.scrollify.previous();
				}
			});

			map.main();

			// オープニング終了後にセクションのアニメーションを起動するため、classを消しておく
			opening.$active = $('[data-section-name].isActive').removeClass('isActive');
		},

		/**
		 * セクションを全取得
		 * @return {jQuery}
		 */
		getAllSection: function(){
			return $('[data-section-name]');
		},

		/**
		 * 名前からセクションを取得
		 * @return {jQuery|Null}
		 */
		getSection: function(sectionName){
			var $section = null;

			if (sectionName) {
				sectionName = sectionName.replace(/^#/, '');

				$section = $('[data-section-name="' + sectionName + '"]');

				if ($section.length) {
					return $section;
				}
			}

			return null;
		},

		/**
		 * 名前からセクション番号を取得
		 * @return {Number}
		 */
		getSectionIndex: function(sectionName){
			var _index = 0;

			var $section = this.getSection(sectionName);
			if ($section && $section.length) {
				$('[data-section-name]')
					.each(function(index, node){
						if ($(node).attr('data-section-name') == sectionName) {
							_index = index;
							return false;
						}
					})
				;
			}

			return _index;
		},

		/**
		 * current表示切り替え
		 * @return {void}
		 */
		switchCurrent: function(i){
			this.$nav.removeClass('isCurrent');

			var $current = $(this.$nav[i]);
			$current.addClass('isCurrent');

			this.$nav.filter(':not(.isCurrent)').each($.proxy(function(index, node){
				var $link = $(node).children('a');
				var $section = this.getSection($link.attr('href'));
				if ($section) {
					$section.find('video').each(function(index, node){
						if (typeof node.pause == 'function') {
							node.pause();
						}
					});
				}
			}, this));

			if(!mediaQuery.isDeviceSp() && !mediaQuery.isDeviceTab()){
				$current.each($.proxy(function(index, node){
					var $link = $(node).children('a');
					var $section = this.getSection($link.attr('href'));
					if ($section) {
						$section.find('video').each(function(index, node){
							if (typeof node.play == 'function') {
								node.play();
							}
						});
					}
				}, this));
			}

			return;
		},

		/**
		 * スクロールボタン表示切り替え
		 * @return {void}
		 */
		switchScroll: function(i){
			if(i === this.max-1) {
				this.$scrollBtn.addClass('pcDpn');
			}else{
				this.$scrollBtn.removeClass('pcDpn');
			}

			//bodyのclass付け替え
			var className = 'isCurrent0' + i;
			$body.removeClass(function(index, deleteClass){
					return (deleteClass.match(/\bisCurrent\S+/g) || []).join(' ');
				})
				.addClass(className);
			return;
		},

		/**
		 * 画面切り替え
		 */
		pagination: function(){
			this.$nav.find('a').on('click', function(){
				$.scrollify.move($(this).attr('href'));
				return false;
			});

			this.$globalNav.children('a').on('click', function(e){
				var $children = $(this).closest('li').find('.modGlobalNavLower');
				var hash = $(this).attr('href').replace(/^.*?#/, '#');

				if (!mediaQuery.isLayoutSp() || !$children.length) {
					$.scrollify.move(hash);
				}

				if (window.globalNav && (!mediaQuery.isLayoutSp() || !$children.length)) {
					window.globalNav.close();
				}

				return false;
			});
		},

		/**
		 * 前の画面に戻る判定
		 * @return {bool} 戻る場合true
		 */
		isMinus: function(index){
			if(this.current > index){
				return true;
			}
			return false;
		},

		/**
		 * ナビゲーション連動
		 * @return {void}
		 */
		setNavPos: function(i){
			var pos = $(this.$nav[i]).offset().top + ($(this.$nav[i]).innerHeight()/2);
			var barPos = mediaQuery.winH/2;
			var navPos = (this.$nav.offset().top - pos);
			this.$navWrap.css('margin-top', barPos + navPos + 'px');
			return;
		}

	};


	/**
	 * ローディング完了後アニメーション
	 * @namespace
	 */
	var opening = {

		/**
		 * transitionが効かなかった時のタイムアウト時間（transition-delay + 1s）
		 * @param
		 */
		timeout : (3200 + 1000),

		/**
		 * オープニング完了後の遅延時間
		 * @param
		 */
		delay   : 400,

		/**
		 * 遅延タイマーを使う時のプライベート変数
		 * @param
		 * @private
		 */
		_timer  : 0,

		init: function(){
			this.$opening = $('.topOpening');
			this.$inner   = $('.topOpeningInner');
		},

		main: function(){
			this.init();
			this.showOpening();
		},

		/**
		 * オープニンを再生するかどうかの判定
		 * @return {Boolean}
		 */
		isPlayable: function(){
			var isForceShow = location.search.indexOf('intro') != -1;
			return isForceShow || !(window.sessionStorage && sessionStorage.getItem('isIntroShown'));
		},

		/**
		 * アニメーション実行
		 */
		showOpening: function(){
			// 初回以外はイントロスキップ
			if (!this.isPlayable()) {
				$body.removeClass('isLoading');
				this.delay = 0;
				this.endOpening();
			} else {
				if (window.sessionStorage) {
					sessionStorage.setItem('isIntroShown', true);
				}

				//アニメーション開始
				$body.removeClass('isLoading').addClass('isLoaded');

				//一定時間経過後にアニメーション強制終了
				//（transitionend無反応時）
				this._timer = setTimeout($.proxy(this.endOpening, this), this.timeout);

				//初期表示画像のアニメーション終了後に再度ステータス変更
				this.$inner
					.on(
						'mozTransitionEnd.opening webkitTransitionEnd.opening transitionend.opening',
						$.proxy(this.endOpening, this)
					)
				;
			}
		},

		/**
		 * 終了後
		 */
		endOpening: function(){
			if (this._timer) {
				clearTimeout(this._timer);
				this._timer = 0;
			}

			this.$inner.off('mozTransitionEnd.opening webkitTransitionEnd.opening transitionend.opening');

			setTimeout($.proxy(this.onComplete, this), this.delay);
		},

		/**
		 * 終了後
		 */
		onComplete: function(){
			this.$opening.addClass('pcDpn');
			$body.removeClass('isLoaded').addClass('isDone');

			// オープニング終了後にもとの選択セクションのアニメーションを起動
			if (this.$active) {
				this.$active
					.addClass('isActive')
				;
				if (!this.isPlayable()) {
					this.$active
						.addClass('isFast')
					;
				}
			}
/*
			if (window.sessionStorage && sessionStorage.getItem('isAnimateSkip')) {
				$('.topContent').addClass('isAnimateSkip');
				$('.topContentBtn').addClass('animateEnd');
				sessionStorage.removeItem('isAnimateSkip');
			}
*/
		}
	};


	/**
	 * 地図上要素位置
	 * @namespace
	 */
	var map = {
		init: function(){
			this.$parentBox = $('.topContentContact');
			this.defRatio = 1242 / 2048;		//元画像縦横比
		},

		main: function(){
			this.init();
			this.setPosition();
		},

		/**
		 * ポインタと文言
		 * 地図と画面の縦横比で位置調整
		 */
		setPosition: function(){
			if(this.defRatio >= this.getRatio()){
				//cssリセット
				this.$parentBox.removeClass('isPortrait');
			}else{
				//cssリセット
				this.$parentBox.addClass('isPortrait');
			}
		},

		/**
		 * 画面比率
		 * @return {num} 画面サイズ横/縦
		 */
		getRatio: function(){
			return mediaQuery.winH / mediaQuery.winW;
		}
	};



	/**
	 * ローディング
	 * @namespace
	 */
	var loadingAnimation = {
		/**
		 * 完了時の切り替わり遅延
		 * @param
		 */
		delay : 600,

		/**
		 * 読み込みが終わらない時の強制タイムアウト時間
		 * キャッシュから画像がロードされて、イベントを仕掛ける前に読み込みが終わっている場合などに発動する
		 * @param
		 */
		timeout : 5000,

		/**
		 * 読み込み済みアイテム数
		 * @param
		 * @private
		 */
		loadedItems : 0,

		init : function(){
			this.$node     = $('.topLoading');
			this.$progress = $('<div class="progress"></div>');
			this.$bar      = $('<div class="bar"></div>');
			this.$count    = $('<div class="count"></div>');
			this.$items    = $('img, video');
		},

		main : function(){
			this.init();

			var isForceShow = location.search.indexOf('intro') != -1;

			if (window.sessionStorage && window.sessionStorage.getItem('isLoadingShown') && !isForceShow) {
				this.onLoad();
			} else {
				$body.addClass('isLoading').removeClass('isLoaded');

				this.$progress.append(this.$bar);
				this.$progress.append(this.$count);
				this.$node.append(this.$progress);

				this.$items.on('load.loadingAnimation canplay.loadingAnimation', $.proxy(this.onItemLoad, this));
				$(window).on('load.loadingAnimation', $.proxy(this.onLoad, this));
				this.timeoutTimer = setTimeout($.proxy(this.onLoad, this), this.timeout);

				this.$items.filter('video').each(function(index, node){
					if (typeof node.load == 'function') {
						node.load();
					}
				});
			}
		},

		/**
		 * 画面出力
		 */
		display : function(){
			var per = parseInt(this.loadedItems * 100 / (this.$items.length || 1), 10) + '%';
			this.$bar.stop(true).animate({ width : per }, 100);
			this.$count.text(per);
		},

		/**
		 * 個別ローディング
		 */
		onItemLoad : function(e){
			$(e.currentTarget).off('load.loadingAnimation canplay.loadingAnimation');

			this.loadedItems++;
			this.display();
/*
			if (this.loadedItems >= this.$items.length) {
				this.onLoad();
			}
*/
		},

		/**
		 * ロード完了
		 */
		onLoad : function(e){
			this.$items.off('load.loadingAnimation canplay.loadingAnimation');
			$(window).off('load.loadingAnimation');
			clearTimeout(this.timeoutTimer);

			this.loadedItems = this.$items.length;
			this.display();

			if (window.sessionStorage) {
				window.sessionStorage.setItem('isLoadingShown', true);
			}

			if (this.onComplete) {
				setTimeout($.proxy(this.onComplete, this), this.delay);
			}
		},

		/**
		 * ローディング完了後アニメーション
		 */
		onComplete : function(){
			opening.main();
		}
	};


	$(function(){
		//デバイス判定

		if(mediaQuery.isDeviceSp() || mediaQuery.isDeviceTab()){
			// SPではvideoなし
			var video = document.querySelector('video');
			$(video).remove();
		}

		//SP時
		if(mediaQuery.isDeviceSp()){
			$body.addClass('isDeviceSp');

			var ua = navigator.userAgent.toLowerCase();
			var target = /iphone/i;
			if(target.test(ua)){
				$body.addClass('isIos');
			}
		}

		//タブレット時
		if(mediaQuery.isDeviceTab()){
			$body.addClass('isDeviceTab');
		}

		//PC/SP判定
		if(mediaQuery.isLayoutSp()) {
			//SPレイアウト時はローディング完了後のアニメーションなし
			$body.removeClass('isLoaded isLoading').addClass('isDone');

			//グローバルナビゲーション
			globalNav.main();

			//スクロール制御
			scroll.main();
		}else {
			// ローディング制御
			loadingAnimation.main();

			//グローバルナビゲーション
			globalNav.main();

			//スクロール制御
			scroll.main();
		}

		// セクションごとに、部品のアニメーション開始遅延時間を調整
/*
		var baseTransitionDelay = 3;
		var baseTransitionDelayStep = 0.6;
*/
		$('.topContentCommon').each(function(index, node){
/*
			$(node).find('.topContentLine').each(function(index, line){
				$(line).css({ 'transition-delay' : (baseTransitionDelay + index * baseTransitionDelayStep) + 's' });
			});
*/
			$(node).find('.topContentBtn').each(function(index, button){
				$(button).on('transitionend.temp', function(e){
					if (e.target === e.currentTarget) {
						$(e.currentTarget).addClass('animateEnd').off('transitionend.temp');
					}
				});
			});
		});
/*
		// 背景ビデオのサイズと位置調整
		if ($('video').length) {
			var $video = $('.topContentMovie');
			$video._width  = 1920;
			$video._height = 1080;
			$video._aspect = $video._width / $video._height;

			function resetVideoSize(){
				if (!mediaQuery.isLayoutSp()) {
					var windowW = window.innerWidth;
					var windowH = window.innerHeight;
					var windowAspect = windowW / windowH;

					var _width = 0;
					var _height = 0;

					if (windowAspect >= $video._aspect) {
						_width  = windowW;
						_height = windowW / $video._aspect;
					} else {
						_width  = windowH * $video._aspect;
						_height = windowH;
					}
					$video.css({
						width  : _width,
						height : _height,
						margin : ((windowH - _height) / 2) + 'px ' + ((windowW - _width) / 2) + 'px'
					});
				}
			};

			$(window).on('resize', resetVideoSize);
			resetVideoSize();
		}
*/
	});

})(window, jQuery);